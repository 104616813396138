import React from 'react'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 180px;
  border-radius: 3px;
`

const Title = styled.h1`
  color: #4b7bea;
`

const Link = styled(NavLink)`
  color: #777;
  text-decoration: none;
  cursor: pointer;
  font-size: 24px;
  transition: color 0.2s ease-in-out;
  margin-bottom: 8px;

  &.active-link,
  &:hover {
    text-decoration: none;
    color: #4b7bea;
  }
`

const Label = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  color: #dedede;
  font-weight: 500;
  margin-bottom: 4px;
  margin-top: 20px;
`

export default function NavBar() {
  return (
    <Container>
      <Title>Invoices</Title>

      <Label>Hlavní nabídka</Label>
      <Link to="/" exact activeClassName="active-link">
        Přehled
      </Link>
      <Link to="/projekty" activeClassName="active-link">
        Projekty
      </Link>

      <Label>Konfigurace</Label>
      <Link to="/data" activeClassName="active-link">
        Data
      </Link>
      <Link to="/synchronizace" activeClassName="active-link">
        Synchronizace
      </Link>
    </Container>
  )
}
