import React from 'react'
import { observable } from 'mobx'

const data = observable({
  ui: {
    version: '0.c'
  },
  invoices: [],
  projects: []
})

const Context = React.createContext(null)

export function GateProvider({ children, ...props }) {
  return (
    <Context.Provider value={data} {...props}>
      {children}
    </Context.Provider>
  )
}

export function useGate() {
  return React.useContext(Context)
}
