import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import Interface from 'components/Interface'
import 'index.css'
import { GateProvider } from 'gate'

ReactDOM.render(
  <GateProvider>
    <BrowserRouter>
      <Interface />
    </BrowserRouter>
  </GateProvider>,
  document.getElementById('root')
)

serviceWorker.unregister()
