import React from 'react'
import { Route } from 'react-router-dom'
import NavBar from 'components/NavBar'
import Page from 'components/Page'
import Version from 'components/Version'
import Main from 'components/Main'
import Invoices from 'pages/Invoices'

export default function Interface() {
  return (
    <Page>
      <Version />
      <NavBar />
      <Main>
        <Route path="/" exact component={Invoices} />
      </Main>
    </Page>
  )
}
