import React from 'react'
import { observer } from 'mobx-react-lite'
import { useGate } from 'gate'

export default observer(function Invoices() {
  const { invoices } = useGate()

  return (
    <>
      <div>Přehled</div>
      Máš {invoices.length} přidaných položek.
    </>
  )
})
