import React from 'react'
import styled from '@emotion/styled'
import { useGate } from '../gate'

const Container = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 14px;
  color: #999;
`

const Box = styled.div`
  border-radius: 3px;
  background: #4e8;
  color: #fff;
  margin-left: 4px;
  padding: 2px 8px;
`

export default function Version() {
  const { ui } = useGate()

  return (
    <Container>
      Verze <Box>{ui.version} - Alpha build #3</Box>
    </Container>
  )
}
